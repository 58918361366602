import {ControllerParams} from '@wix/yoshi-flow-editor'
import {getInstalledAppsState} from '../../../commons/services/installed-apps'
import {getMultilingualInitialState} from '../../../commons/services/multilingual'
import {SiteSettings} from '../../../commons/types/state'
import {getDemoEvent} from '../Widget/services/demo-event'
import {State} from '../Widget/types/state'
import {Api} from '../Widget/utils/api'
import {parseLocation} from '../Widget/utils/navigation'
import {getDraftToken} from '../Widget/utils/query'
import {getStateFromInvoice} from './state-from-invoice'

export const getInitialData = async (serverApi: Api, controller: ControllerParams): Promise<Partial<State>> => {
  const {wixCodeApi} = controller.controllerConfig
  const flowAPI = controller.flowAPI
  const navigation = parseLocation(wixCodeApi, controller.flowAPI)
  const {slug} = navigation
  const responsive = flowAPI.environment.isEditorX
  const currentUser = wixCodeApi.user.currentUser
  const installedApps = await getInstalledAppsState(controller)

  const {
    event,
    richContent,
    siteSettings,
    demoEvents,
    tickets,
    demoTickets,
    schedule,
    dates,
    upcomingOccurrencesCount,
  } = await serverApi.getData({
    flowAPI,
    slug,
    responsive,
    schedulePageInstalled: installedApps.schedule,
    draftPreviewToken: getDraftToken(wixCodeApi),
  })

  const invoiceState = await getStateFromInvoice({serverApi, event, navigation})

  return {
    event: {
      event: !event && demoEvents ? getDemoEvent(demoEvents, slug, responsive) : event,
      richContent,
    },
    siteSettings: siteSettings as SiteSettings,
    demoEvents,
    tickets,
    demoTickets,
    schedule,
    upcomingOccurrencesCount,
    multilingual: getMultilingualInitialState(wixCodeApi),
    navigation,
    policies: {
      agreed: false,
      policies: [],
      showAlert: false,
    },
    dates,
    currentUser: {
      id: currentUser.id,
      role: currentUser.role,
      loggedIn: currentUser.loggedIn,
    },
    ...invoiceState,
    installedApps,
  }
}
